/*eslint-disable*/
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import PrivateRouteFactory from 'common/components/Routes/PrivateRoute';
import WordCloud from 'app/Pages/WordCloud';
import Config from 'app/Pages/Config';
import routerMap from './RouterMap';
import './styles.scss';

const PrivateRoute = PrivateRouteFactory(routerMap.wordcloud.path);

const Main = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <PrivateRoute exact path={routerMap.wordcloud.path} component={WordCloud} />
          <PrivateRoute exact path={routerMap.config.path} component={Config} />
        </Switch>
      </Router>
    </div>
  );
}

export default Main;
