import React from 'react';
import { func, node, string } from 'prop-types';
import { Form } from 'react-bootstrap';
import cx from 'classnames';
import Button from 'common/components/Buttons/Button';
import './styles.scss';

const SPACE = 'SPACE';
const INSTANCE = 'INSTANCE';

function ToggleButton({ active, children, onChange, value }) {
  const isActive = value == active;
  const handleClick = () => onChange(value);
  return (
    <Button
      onClick={handleClick}
      variant={isActive ? 'info' : 'secondary'}
      className={cx('toggle-button', !isActive && 'inactive')}
    >
      {children}
    </Button>
  );
}

ToggleButton.propTypes = {
  active: string.isRequired,
  children: node.isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
};

function SelectSource({ value, onChange }) {
  const handleChange = (newValue) => {
    onChange(newValue);
  };
  return (
    <div>
      <Form.Label className="font-weight-bold">
        Select the data source
      </Form.Label>
      <div className="d-flex">
        <ToggleButton value={SPACE} active={value} onChange={handleChange}>
          Space
        </ToggleButton>
        <ToggleButton value={INSTANCE} active={value} onChange={handleChange}>
          Instance
        </ToggleButton>
      </div>
    </div>
  );
}

SelectSource.propTypes = {
  onChange: func.isRequired,
  value: string.isRequired,
};

export default SelectSource;
