/* eslint-disable */

import React from 'react';
import cx from 'classnames';
import { string } from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

function LoadingSpinner({ message, className }) {
  return (
    <div className={cx('loader d-flex justify-content-center align-items-center', className)}>
      <div className="d-flex flex-column align-items-center">
        <Spinner className="text-info" animation="border" />
        <div className="tmp-loader font-weight-bold text-info mb-2">
          {message}
        </div>
      </div>
    </div>
  );
}

LoadingSpinner.propTypes = {
  message: string,
};

LoadingSpinner.defaultProps = {
  message: '',
};

export default LoadingSpinner;
