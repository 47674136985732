import ausppClient from 'Config/AusppClient';

const DEFAULT = {
  colors: ['#303F9F', '#7C4DFF', '#3F51B5', '#757575'],
  source: 'INSTANCE',
};

async function GetConfigByTile(tileId, responseHandlers) {
  try {
    const response = await ausppClient(`tiles/api/v1/configurations/${tileId}`);
    const mapped = response;
    responseHandlers.onSuccess(mapped.content);
  } catch (error) {
    if (error.status == 404) {
      responseHandlers.onSuccess(DEFAULT);
    }
    responseHandlers.onError(error);
  }
}

export default GetConfigByTile;
