import React from 'react';
import fromQS from 'common/utils/fromQS';
import ConfigView from 'app/Views/Configuration';
import PostConfigCompleted from 'app/Services/Events/PostConfigCompleted';

const ConfigPage = () => {
  const tileId = fromQS('tile_id');
  const tenantUrl = fromQS('tenant');
  const placeId = fromQS('place_id');

  const handleOnSave = (data) => {
    PostConfigCompleted(data);
  };
  return (
    <ConfigView
      tileId={tileId}
      onSave={handleOnSave}
      tenantUrl={tenantUrl}
      placeId={placeId}
    />
  );
};

export default ConfigPage;
