import React from 'react';
import { arrayOf, func, number, string } from 'prop-types';
import { Form } from 'react-bootstrap';
import './styles.scss';

function ColorInput({ value, index, onChange }) {
  const handleChange = (change) => {
    onChange(index, change.target.value);
  };
  return (
    <div className="px-2 py-1 d-flex">
      <div style={{ background: value }} className="color-display" />
      <Form.Control
        type="text"
        className="color-input"
        value={value}
        onChange={handleChange}
      />
    </div>
  );
}

ColorInput.propTypes = {
  index: number.isRequired,
  onChange: func.isRequired,
  value: string.isRequired,
};

function ColorTheme({ colors, onChange }) {
  const handleInputChange = (index, newVal) => {
    const changes = [...colors];
    changes[index] = newVal;
    onChange(changes);
  };

  return (
    <div>
      <Form.Label className="font-weight-bold">Color Scheme</Form.Label>
      {colors.map((color, index) => (
        <ColorInput index={index} value={color} onChange={handleInputChange} />
      ))}
    </div>
  );
}

ColorTheme.propTypes = {
  colors: arrayOf(string).isRequired,
  onChange: func.isRequired,
};

export default ColorTheme;
