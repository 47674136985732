import React, { useEffect } from 'react';

import { useRequest } from 'common/utils/Request';
import fromQS from 'common/utils/fromQS';
import LoadingSpinner from 'common/components/LoadingSpinner';
import WordCloud from 'app/Views/WordCloud';
import GetWordCloudByTile from 'app/Services/WordCloud/GetByTile';
import './styles.scss';

const WordCloudPage = () => {
  const tileId = fromQS('tile_id');
  const getCloudByTile = useRequest(GetWordCloudByTile, {
    onSuccess: () => {},
    onError: () => {},
  });

  useEffect(() => {
    getCloudByTile.start(tileId);
  }, []);

  return (
    <div className="wordcloud-page">
      {getCloudByTile.inProcess && (
        <LoadingSpinner message="Loading" className="loading-container" />
      )}
      {getCloudByTile.response && !getCloudByTile.inProcess && (
        <WordCloud
          words={getCloudByTile.response.words}
          colors={getCloudByTile.response.colors}
        />
      )}
    </div>
  );
};

export default WordCloudPage;
