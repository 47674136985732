import React from 'react';
import BaseWordCloud from 'react-wordcloud';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale.css';
import { arrayOf, number, shape, string } from 'prop-types';

const options = {
  rotations: 2,
  rotationAngles: [-90, 0],
};

function WordCloud({ colors, words }) {
  return (
    <BaseWordCloud
      words={words}
      options={{
        ...options,
        colors,
        deterministic: true,
      }}
      // eslint-disable-next-line react/jsx-boolean-value
    />
  );
}

WordCloud.propTypes = {
  colors: arrayOf(string).isRequired,
  words: arrayOf(
    shape({
      color: string,
      fontWeight: number,
      text: string,
      value: number,
    }),
  ).isRequired,
};

export default WordCloud;
