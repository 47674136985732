import http from 'common/utils/Http';
import config from './index';

const baseURL = config.get('configAPI');

class CustomError extends Error {
  constructor(status, message) {
    super();
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomError);
    }
    this.name = 'Api_Error';
    // Información de depuración personalizada
    this.status = status;
    this.message = message;
  }
}

export default async function apiClient(url, params) {
  const options = {
    ...params,
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (params && params.body) {
    options.body = JSON.stringify(params.body);
  }
  const response = await http(`${baseURL}${url}`, options);
  if (response.status > 299) throw new CustomError(response.status);
  return response;
}
