import ausppClient from 'Config/AusppClient';

async function UpdateConfiguration({ tileId, content }, responseHandlers) {
  try {
    const response = await ausppClient(
      `tiles/api/v1/configurations/${tileId}/save`,
      {
        method: 'POST',
        body: {
          id: tileId,
          content,
        },
      },
    );
    const mapped = response;
    responseHandlers.onSuccess(mapped.content);
  } catch (error) {
    if (responseHandlers.onError) responseHandlers.onError(error);
  }
}

export default UpdateConfiguration;
