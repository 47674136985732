import ausppClient from 'Config/AusppClient';

async function GetWordCloudByTile(tileId, responseHandlers) {
  try {
    const response = await ausppClient(`word-cloud?tile_id=${tileId}`);

    responseHandlers.onSuccess(response);
  } catch (error) {
    responseHandlers.onError(error);
  }
}

export default GetWordCloudByTile;
