import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { func, string } from 'prop-types';
import { isEmpty } from 'lodash';
import { useRequest } from 'common/utils/Request';
import LoadingSpinner from 'common/components/LoadingSpinner';
import Button from 'common/components/Buttons/Button';
import ColorTheme from 'app/Views/ColorTheme';
import SelectSource from 'app/Views/SelectSource';

import GetWordCloudByTile from 'app/Services/Configuration/GetConfigByTile';
import UpdateConfiguration from 'app/Services/Configuration/UpdateConfiguration';

import './styles.scss';

const ConfigPage = ({ tileId, onSave, tenantUrl, placeId }) => {
  const [formState, setForm] = useState({});
  const [initial, setInitial] = useState({
    tenantUrl,
    placeId,
  });
  const getConfigByTile = useRequest(GetWordCloudByTile, {
    onSuccess: (data) => {
      if (isEmpty(data)) return;
      const initialConfig = {
        ...initial,
        colors: data.colors,
        source: data.source,
      };
      setForm(initialConfig);
      setInitial(initialConfig);
    },
  });
  const updateConfig = useRequest(UpdateConfiguration, {
    onSuccess: (data) => {
      const initialConfig = {
        colors: data.colors,
        source: data.source,
      };
      setForm(initialConfig);
      setInitial(initialConfig);
      onSave(data);
    },
  });

  useEffect(() => {
    getConfigByTile.start(tileId);
  }, []);

  const handleColorChange = (change) => {
    setForm({
      ...formState,
      colors: change,
    });
  };

  const handleSourceChange = (change) => {
    setForm({
      ...formState,
      source: change,
    });
  };

  const handleSave = () => {
    updateConfig.start({ tileId, content: formState });
  };

  const handleCancel = () => {
    setForm(initial);
  };

  return (
    <div className="wordcloud-page">
      {updateConfig.inProcess && (
        <div className="in-process-bg d-flex justify-content-center">
          <LoadingSpinner message="...Saving" className="loading-container" />
        </div>
      )}
      {getConfigByTile.inProcess && (
        <LoadingSpinner
          message="Loading tile configuration"
          className="loading-container"
        />
      )}
      {!isEmpty(formState) && !getConfigByTile.inProcess && (
        <div className="mx-3 my-1">
          <h4>Configuration</h4>
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                  Color Scheme
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <ColorTheme
                    colors={formState.colors}
                    onChange={handleColorChange}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                  Data Source
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <SelectSource
                    value={formState.source}
                    onChange={handleSourceChange}
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
          <div className="d-flex justify-content-center my-2">
            <Button
              onClick={handleCancel}
              size="md"
              variant="secondary"
              className="mr-2"
            >
              Cancel
            </Button>
            <Button onClick={handleSave} size="md">
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

ConfigPage.propTypes = {
  onSave: func.isRequired,
  placeId: string.isRequired,
  tenantUrl: string.isRequired,
  tileId: string.isRequired,
};

export default ConfigPage;
