class Config {
  envs = {};

  constructor() {
    this.envs.configAPI = process.env.REACT_APP_CONFIG_API;
    this.envs.env = process.env.NODE_ENV;
  }

  get = (key) => this.envs[key];
}

export default new Config();
